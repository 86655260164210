import React from "react";
import Layout from "components/layout";
import SEO from "components/seo";
import RichText from "components/rich-text";
import Box from "components/box";
import Logo from "svg/logo.svg";

import "./style.css";

const text = `
## Info & Contact

- [afk@ellugar.co](mailto:afk@ellugar.co)
- [github.com/afk_mario](http://github.com/afk_mario)
- [lnkd.in/99e6bA](http://lnkd.in/99e6bA)
- Spanish: Native speaker.
- English: Advanced.

## CTO - [Amano](https://amano.games/) (06/2020–Present)

Small game studio by a couple of friends. I'm in charge of all programming and technical aspects of the company, and we have launched three PICO-8 games and one [PlayDate game published by Panic](https://play.date/games/pullfrog/)

## Core maintainer - [INDI.ES](https://indi-es.com/) (06/2017–Present)

I'm one of the core maintainers of one of the biggest game dev communities in Spanish, I helped with the data visualization for the [Mexican games database](https://indi-es.com/juegos) and [Mexican game studio database,](https://indi-es.com/estudios) and overall most of the INDI.ES website development.

## Lead Front End Developer - [Scalero](https://scalero.io/) (05/2020–04/2023)

My responsibilities included migrating all the applications from a Django code base to an SPA using React, implementing our custom desing system and building an interactive Email code editor.

## Director - Farda (01/2020–04/2020)

Small indie game studio based in Mexico City farda.games [farda.games](https://farda.games/)

## Lead Front End Developer - [Aleph](https://www.alephri.com/) (2018–2020)

My responsibilities included implementing a custom design system for the platform, overall architecture for multiple data visualizations, including an interactive map built with WEBGL.

## Game programmer - HyperBeard Games (2014–2017)

HyperBeard Games started as a two-person company in Mexico City. I was the third member to join the team, and we took it from a garage studio to one of the most successful game studios in Mexico, working on titles with over 10 million downloads. Some projects in which I was involved include:

- Kleptocats [kleptocats.com](https://kleptocats.com/)
- Balloons  [hyperbeard.com](https://hyperbeard.com/game/the-balloons/)
- Muertitos  [hyperbeard.com](https://hyperbeard.com/game/muertitos/)

## Producer and host - Detrás del Pixel (2017–present)

[Detrasdelpixel.com](https://detrasdelpixel.com/) is a podcast about the Game development scene in Spanish, currently 34+ episodes have been released, where I talk with people from all over the world and about the different parts that involves the Game Development industry and what happens behind it.

## Educator - Universidad Panamericana (2020) / Cocos School, Mexico (2018–2020)

- Game Engines
- Programming I
- Web programming

## Education

B.A. in Digital Animation and Minor in Project Administration at Universidad Panamericana in Mexico City.
`;

const disclaimer = `
_For a more in-depth summary of the projects that are listed here, and my involvement visit [https://ellugar.co](ellugar.co)_
`;

const CV = () => {
  return (
    <Layout className="cv-page">
      <SEO title="Home" />
      <div className="wrapper">
        <Box className="home-box">
          <h1>Mario Carballo Zama</h1>
          <Logo className="logo -hide-screen" />
        </Box>
        <RichText text={text} />
        <RichText className="disclaimer -hide-screen" text={disclaimer} />
      </div>
    </Layout>
  );
};

export default CV;
